.card-img, .card-img-bottom, .card-img-top {width: 200px}
.social-buttons{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: space-around;
    justify-content: space-between;
    padding: 1px;

}
.card{
    width: 22rem;
    align-items: center;
    text-align: center;
    padding: 15px;
    border: 8px solid #b909c9;
}

.expandedProfile {
    width: 35rem;
}

.expanded-profile-body {
    display: grid;
}

.avatar {
    margin: 20px
}
.profileCard {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;
    /* grid-template-columns: repeat(3, 200px); */
    justify-content: space-around;
    /* align-items: center;
    justify-items: stretch;
    align-content: space-evenly; */
}
.littleCard {
    max-width: 30%;
}